<template>
  <Transition name="fade">
    <aside
      v-if="showBackdropWithAppsLink"
      id="UseMobileView"
      class="Modal_backdrop Modal_backdrop--over-view"
    >
      <div class="h-100">
        <Transition name="fade">
          <section class="d-flex flex-column align-items-center p-4 w-100 h-100 emobg-background-color-success-light">
            <ui-image
              :alt="$t('settings.no_desktop')"
              :src="downloadAppImageNoBg"
              :height="330"
              class="mb-4"
            />
            <div class="d-flex flex-column align-items-center">
              <h2 class="Modal_backdrop__text mt-4 mb-3 emobg-font-x-large">
                {{ $t('use_mobile.title') }}
              </h2>

              <p class="Modal_backdrop__text mb-3 emobg-font-small">
                {{ $t('use_mobile.description') }}
              </p>

              <div class="d-flex">
                <a :href="links.ios">
                  <ui-image
                    :src="icons.DownloadAppStoreDefault"
                    alt="iOS"
                    class="mr-3"
                  />
                </a>
                <a :href="links.android">
                  <ui-image
                    :src="icons.DownloadGooglePlayDefault"
                    alt="Android"
                  />
                </a>
              </div>
            </div>
          </section>
        </Transition>
      </div>
    </aside>
  </Transition>
</template>

<script>
import { getValue } from '@emobg/web-utils';

// Images
import DownloadAppStoreDefault from '@/assets/images/icons/download-app-store-default.svg';
import DownloadGooglePlayDefault from '@/assets/images/icons/download-google-play-default.svg';

// Constants
import { useRoute } from 'vue-router/composables';
import { downloadAppImageNoBg } from '@/utils/publicImages';
import { ANDROID, IOS } from './constants/appLinks.const';

export default {
  name: 'UseMobile',
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  computed: {
    isResetPassword() {
      return /reset-password/.test(this.route.fullPath);
    },
    isTariffs() {
      return /tariffs/.test(this.route.fullPath);
    },
    isLegal() {
      return /legal/.test(this.route.fullPath);
    },
    isSignUp() {
      return /signup|sign-up/.test(this.route.fullPath);
    },
    requestByMobile() {
      return getValue(this.route, 'query.mobile', false);
    },
    showBackdropWithAppsLink() {
      return !getValue(this.route, 'meta.responsive', false)
        && !this.isResetPassword
        && !this.isTariffs
        && !this.isLegal
        && !this.isSignUp;
    },
  },
  created() {
    this.icons = {
      DownloadAppStoreDefault,
      DownloadGooglePlayDefault,
    };
    this.links = {
      android: ANDROID,
      ios: IOS,
    };
    this.downloadAppImageNoBg = downloadAppImageNoBg;
  },
};

</script>
<style lang="scss">
.Modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 280;
  width: 100%;
  height: 100%;
  overflow: auto;

  &--over-view {
    z-index: 100000;
  }

  &__text {
    text-align: center;
  }
}
</style>
